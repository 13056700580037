import { useMemo } from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';
import { getPizzaRecipes } from '@/services/pizzaMenuService/menuService';
import { NewBadgeDecisionVariables as TavernNationalLaunchVariables } from '@/builders/pizza/setters/Toppings/ToppingsPicker/hooks/useNewToppingBadge';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';

interface PizzaRecipeCategories {
  createYourOwnPizza: MenuRecipe[];
  tavernPizzas: MenuRecipe[];
  signaturePizzas: MenuRecipe[];
}

export const getPizzaId = (fullId?: string) => fullId?.split('/').pop() ?? '';

export const useGetPizzaMenuCategories = (pizzaRecipes: MenuRecipe[] | DealRecipe[] = []): PizzaRecipeCategories => {
  const [{ variables: tavernNationalLaunchVariables }] = useDecision('cb-tavern_national_launch', { autoUpdate: true });

  return useMemo(() => {
    const tavernPizzaIds = (tavernNationalLaunchVariables as TavernNationalLaunchVariables)?.tavern_pizza_ids?.pizzas?.ids ?? [];
    const { createYourOwnPizza, popularPizzas } = getPizzaRecipes(pizzaRecipes);
    const signaturePizzas: MenuRecipe[] = [];
    const tavernPizzas: MenuRecipe[] = [];
    popularPizzas.forEach((recipe) => {
      if (tavernPizzaIds.includes(getPizzaId(recipe.id))) {
        tavernPizzas.push(recipe);
      } else {
        signaturePizzas.push(recipe);
      }
    });
    return {
      createYourOwnPizza,
      signaturePizzas,
      tavernPizzas
    };
  }, [pizzaRecipes, tavernNationalLaunchVariables]);
};
