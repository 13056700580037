import Typography from '@material-ui/core/Typography';
import React from 'react';
import { useDecision } from '@optimizely/react-sdk';
import { useSelector } from 'react-redux';
import formattedPrice from '../formattedPrice';
import { HOT_HONEY_PARTIAL, TAVERN_PIZZA } from '@/builders/pizza/constants';
import useHidePriceHardcoding from '../../../hardcoding/utils/useHidePriceHardcoding';
import { RootState } from '@/rootStateTypes';

export interface PriceDisplayProps {
  price?: number;
  displayPrice?: string;
  showPrefix?: boolean;
  name?: string;
  isDeal?: boolean;
  productId?: string;
}

const PriceDisplay = ({
  price, displayPrice, showPrefix = true, name, productId, isDeal
}: PriceDisplayProps): JSX.Element => {
  const { shouldHidePrice } = useHidePriceHardcoding(name, productId, isDeal);
  const [{ enabled: showPricingPrefixForDeals }] = useDecision('fr-dtg-1004-deals-pricing-prefix-temp');
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);
  const pricingPrefixforNonYumDeals = showPricingPrefixForDeals && !isYumEcomm;

  if (shouldHidePrice) {
    return <></>;
  }

  if (typeof price !== 'number') return <></>;

  // WEB-2029 - do not show price prefix for Hot Honey pizza
  const isHotHoney = name?.includes(HOT_HONEY_PARTIAL);

  let startingAtPrefix = 'Large s';
  if (name?.toLowerCase().includes('triple treat box') || name?.toLowerCase().includes('big dinner box')
    || pricingPrefixforNonYumDeals) {
    startingAtPrefix = 'S';
  } else if (name?.toLowerCase().includes('detroit')) {
    startingAtPrefix = 'Medium s';
  } else if (name?.toLowerCase().includes(TAVERN_PIZZA.toLowerCase())) {
    startingAtPrefix = 'Large Tavern recipes s';
  }

  const showPricingPrefix = showPrefix && (!isHotHoney || pricingPrefixforNonYumDeals);
  return (
    <Typography data-testid="price-display">
      {showPricingPrefix && `${startingAtPrefix}tarting at`} {displayPrice || formattedPrice(price)}
    </Typography>
  );
};

export default PriceDisplay;
