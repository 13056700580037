import { isCreateYourOwnPizza } from '@/builders/pizza/identifiers';
import { DealRecipe } from '@/builders/deals/slice/dealTypes';
import { filter } from 'remeda'
import { MenuRecipe } from '@/menu/pizza/pizzaMenuTypes';

interface PizzaRecipes {
  hasRecipes: boolean;
  createYourOwnPizza: MenuRecipe[];
  popularPizzas: MenuRecipe[];
  isLocalized: boolean;
}

export function getPizzaRecipes(
  recipes:  MenuRecipe[] | DealRecipe[] | undefined
): PizzaRecipes {
  if (recipes && recipes.length > 0) {
    const createYourOwnPizza = filter(recipes as unknown as MenuRecipe[], (item) => isCreateYourOwnPizza(item as MenuRecipe | DealRecipe))
    const popularPizzas = filter(recipes as unknown as MenuRecipe[],
      (item) => !isCreateYourOwnPizza(item as MenuRecipe | DealRecipe)
    ) as MenuRecipe[]

    return {
      hasRecipes: true,
      createYourOwnPizza,
      popularPizzas,
      isLocalized: true
    };
  }

  return {
    hasRecipes: false,
    createYourOwnPizza: [],
    popularPizzas: [],
    isLocalized: true
  };
}