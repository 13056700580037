import { useDecision } from '@optimizely/react-sdk';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NewBadgeDecisionVariables as TavernNationalLaunchVariables } from '@/builders/pizza/setters/Toppings/ToppingsPicker/hooks/useNewToppingBadge';
import { productCheck } from '@/common/PriceDisplay/ProductPrice/hooks/useProductPrice';
import { getPizzaId } from '@/menu/hooks/useGetPizzaMenuCategories';
import { dealSelectors } from '@/builders/deals/slice/deal.slice';
import { TAVERN_PIZZA } from '@/builders/pizza/constants';
import { RootState } from '@/rootStateTypes';

interface UseHidePriceHardCoding {
  shouldHidePrice: boolean;
  isDealTile: boolean;
}

const useHidePriceHardCoding = (name?: string, productId?: string, isDeal = false): UseHidePriceHardCoding => {
  const [{ variables: tavernNationalLaunchVariables }] = useDecision('cb-tavern_national_launch', { autoUpdate: true });
  const [{ enabled: showAllDealRecipePrices }] = useDecision('cb-show_all_deal_recipe_prices');
  const [{ enabled: showTavernRecipePricesFF }] = useDecision('cb-show_tavern_recipe_prices');

  const deal = useSelector(dealSelectors.selectDeal);
  const isYumEcomm: boolean = useSelector((state: RootState) => state.coreConfig.isYumEcomm);

  const isDealPizzaRecipe = useMemo(() => !!deal?.data?.steps?.some((dealStep) => !!dealStep?.recipeOptions?.some((recipe) => recipe.id === productId && recipe.type === 'PIZZA')),
    [deal?.data?.steps, productId]);

  const tavernPizzaIds = (tavernNationalLaunchVariables as TavernNationalLaunchVariables)?.tavern_pizza_ids?.pizzas?.ids ?? [];
  const isTavernPizza = productId && tavernPizzaIds.includes(getPizzaId(productId));
  const isTavernPizzaDeal = deal?.data?.name.toLowerCase().includes(TAVERN_PIZZA.toLowerCase());
  const isCYO = productCheck(name ?? '', 'create your own');

  // show pricing for CYO and Recipes under tavern deal and hide for all other deals
  const shouldShowTavernRecipePrices = showTavernRecipePricesFF && isDealPizzaRecipe && (isTavernPizza || isCYO) && !isYumEcomm && isTavernPizzaDeal;
  const shouldHideDealRecipePrices = isDealPizzaRecipe && !showAllDealRecipePrices && !shouldShowTavernRecipePrices;
  return {
    shouldHidePrice: shouldHideDealRecipePrices,
    isDealTile: !isDealPizzaRecipe
  };
};

export default useHidePriceHardCoding;
