import { useDecision } from '@optimizely/react-sdk';
import { TAVERN_PIZZA } from '@/builders/pizza/constants';

const useDealPricingPrefix = (id = ''):boolean => {
  const [tavernNationalLaunchDecision] = useDecision('cb-tavern_national_launch', { autoUpdate: true });

  // return false FF is turned OFF
  if (!tavernNationalLaunchDecision?.enabled) {
    return false;
  }

  return id.toLowerCase().includes(TAVERN_PIZZA.toLowerCase());
};

export default useDealPricingPrefix;
